import { type Session, type Review } from '@prisma/client'

export interface ReviewWithReviewerId extends Review {
  reviewerId: number
}

function calculateStarsAverage<T extends { stars: number }>(
  reviews: T[],
): number {
  if (!reviews.length) return 0

  const starsSummation = reviews.reduce((acc, review) => acc + review.stars, 0)
  return starsSummation / reviews.length
}

export function getStarsAndReviewDataForProfesional(profesional: {
  services: {
    serviceBooked: {
      clientId: Session['clientId']
      review?: Review | null
    }[]
  }[]
}) {
  if (!profesional?.services) {
    return { stars: 0, reviews: [] }
  }

  const reviews = profesional.services.flatMap(service =>
    getReviewsFromService<Review>(service),
  )

  return {
    stars: calculateStarsAverage(reviews),
    reviews,
  }
}

export function getServicesStars(
  services: {
    serviceBooked: {
      review?: { stars: Review['stars'] } | null
    }[]
  }[],
) {
  if (!services.length) return 0

  const reviews = services.flatMap(service =>
    getReviewsFromService<{ stars: Review['stars'] }>(service),
  )

  return calculateStarsAverage(reviews)
}

function getReviewsFromService<T>(service: {
  serviceBooked: {
    review?: T | null
    clientId: Session['clientId']
  }[]
}): Array<T & { reviewerId: Session['clientId'] }>

function getReviewsFromService<T>(service: {
  serviceBooked: {
    review?: T | null
  }[]
}): Array<T>

function getReviewsFromService<T>(service: {
  serviceBooked: {
    review?: T | null
    clientId?: Session['clientId']
  }[]
}) {
  return service.serviceBooked.flatMap(session => {
    if (!session.review) {
      return []
    }

    if (session.clientId) {
      return {
        ...session.review,
        reviewerId: session.clientId,
      }
    }
    return session.review
  })
}
